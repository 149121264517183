import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import SEO from "../components/seo"

const AllPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
      ) {
        edges {
          node {
            frontmatter {
              title
              path
              date(formatString: "MMMM DD, YYYY")
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title="All" />
      <ul>
      {data.allMarkdownRemark.edges.map((note, i) => (
        <li key={i}>
          <Link to={note.node.frontmatter.path}>{note.node.frontmatter.title}</Link> - <em>{note.node.frontmatter.date}</em>
        </li>
      ))}
      </ul>
    </>
  )
}

export default AllPage

